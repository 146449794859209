import React from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../components/layout"
import Seo from "../components/seo"

//sections
import BlogPost from './sections/blog/blogPost';

const Blog = ({data}) => {
  const mainData = data.allWpPage.nodes[0].blogACF;
  return (
    <Layout>
      <Seo title="Blog" />
      <div className="page-wrapper blogs-page" id="blogs-page-wrapper">
        <BlogPost 
            mainTitle={parse(mainData.blogsTitle)}
            blogList={mainData.blogsList}
        />
      </div>
    </Layout>
  )
}

export const blogQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "blog"}}) {
    nodes {
      blogACF {
        blogsTitle
        blogsList {
          blogImage1x {
            altText
            sourceUrl
            width
          }
          blogImage2x {
            altText
            sourceUrl
            width
          }
          blogDate
          blogTitle
          tags {
            tagName
          }
          minutesRead
          blogUrl
        }
      }
    }
  }
}`

export default Blog;

