import React from 'react';
import { Link } from "gatsby"
import shortid from  "shortid";
const BlogPost = (props) => {

    return (
        <section className="blog-post">
            <div className="container">
                <div className="row">
                    <div className="col-lg-21">
                        <h1 className="h1 section-title">{props.mainTitle}</h1>
                    </div>
                </div>
                <div className="blog-list">
                    {props.blogList?.map((object, i) => {
                        return(
                            <div className="row" key={shortid.generate()}>
                                <div className="col-lg-9">
                                    <div className="image-block">
                                        <img
                                            src={object.blogImage1x?.sourceUrl}
                                            srcSet={object.blogImage2x?.sourceUrl + " 2x, " + object.blogImage1x?.sourceUrl + " 1x"}
                                            width={object.blogImage1x?.width}
                                            alt={object.blogImage1x?.altText}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                <div className="col-lg-14">
                                    <div className="content-block">
                                        <p className="small blog-date">{object.blogDate}</p>
                                        <Link to={object.blogUrl} className="blog-title"><h3 className="h3 large ">{object.blogTitle}</h3></Link>
                                        <div className="blog-tags">
                                            {object.tags?.map((tag) => {
                                                return(
                                                    <span className="tag primary-tag">{tag.tagName}</span>
                                                )
                                            })}
                                            <p className="small blog-min-read">{object.minutesRead}</p>
                                        </div>
                                        <div className="btn-block"><Link to={object.blogUrl} className="btn outline-btn" >Read More</Link></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default BlogPost